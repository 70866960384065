<template>
  <div>
    <q-form ref="editForm">
      <div class="row">
        <div class="col-xs-12 col-sm-12 col-md-3 col-lg-3 col-xl-3">
          <c-card title="선임명 정보" class="cardClassDetailForm">
            <template slot="card-detail">
              <div class="col-xs-12 col-sm-12 col-md-12 col-lg-12 col-xl-12">
                <c-text
                  :required="!isOld"
                  :editable="editable"
                  :disabled="true"
                  :afterIcon="editable && !isOld ? [
                    { name: 'search', click: true, callbackName: 'searchSenior', color: 'teal' },
                    { name: 'close', click: true, callbackName: 'removeSenior', color: 'red' }
                  ] : void 0"
                  label="선임명"
                  name="seniorName"
                  v-model="seniorDismissalStatus.seniorName"
                  @searchSenior="openSenior"
                  @removeSenior="removeSenior">
                </c-text>
              </div>
              <div class="col-xs-12 col-sm-12 col-md-12 col-lg-12 col-xl-12">
                <c-multi-select
                  :editable="editable"
                  :disabled="true"
                  :isArray="false"
                  codeGroupCd="RELATED_LAWS_CD"
                  itemText="codeName"
                  itemValue="code"
                  label="관련법규"
                  name="relatedLaws"
                  v-model="seniorDismissalStatus.relatedLaws">
                </c-multi-select>
              </div>
              <div class="col-xs-12 col-sm-12 col-md-12 col-lg-12 col-xl-12">
                <c-textarea
                  :editable="editable"
                  :disabled="true"
                  :rows="2"
                  name="qualifiyRequire"
                  label="자격요건"
                  v-model="seniorDismissalStatus.qualifiyRequire"
                ></c-textarea>
              </div>
              <div class="col-xs-12 col-sm-12 col-md-12 col-lg-12 col-xl-12">
                <c-textarea
                  :editable="editable"
                  name="relatedReason"
                  label="관련근거"
                  :rows="2"
                  v-model="seniorDismissalStatus.relatedReason"
                ></c-textarea>
              </div>
              <div class="col-xs-12 col-sm-12 col-md-12 col-lg-12 col-xl-12">
                <c-textarea
                  :editable="editable"
                  name="seniorReason"
                  label="수행역할"
                  :rows="2"
                  v-model="seniorDismissalStatus.seniorReason"
                ></c-textarea>
              </div>
            </template>
          </c-card>
        </div>
        <div class="col-xs-12 col-sm-12 col-md-9 col-lg-9 col-xl-9">
          <c-card title="선임 정보" class="cardClassDetailForm">
            <template slot="card-button">
              <q-btn-group outline >
                <!-- <c-btn
                  v-show="editable && isOld"
                  label="새로운 선임자 등록"
                  icon="add"
                  @btnClicked="addData"/> -->
                <c-btn
                  v-show="editable && isOld"
                  label="삭제"
                  icon="remove"
                  @btnClicked="deleteData"/>
                      <c-btn
                  v-show="editable"
                  :url="saveUrl"
                  :isSubmit="isSave"
                  :param="seniorDismissalStatus"
                  :mappingType="mappingType"
                  label="저장"
                  icon="save"
                  @beforeAction="saveData"
                  @btnCallback="saveCallback" />
              </q-btn-group>
            </template>
            <template slot="card-detail">
              <div class="col-xs-12 col-sm-12 col-md-3 col-lg-3 col-xl-3">
                <c-plant
                  required
                  :disabled="isOld"
                  :editable="editable"
                  type="edit"
                  name="plantCd"
                  v-model="seniorDismissalStatus.plantCd">
                </c-plant>
              </div>
              <div class="col-xs-12 col-sm-12 col-md-3 col-lg-3 col-xl-3">
                <c-field 
                  required
                  :editable="editable"
                  :data="seniorDismissalStatus"
                  type="dept_user" 
                  deptValue="seniorDeptCd"
                  label="부서 & 선임자" 
                  name="seniorUserId" 
                  v-model="seniorDismissalStatus.seniorUserId" />
              </div>
              <div class="col-xs-12 col-sm-12 col-md-3 col-lg-3 col-xl-3">
                <c-datepicker
                  required
                  :editable="editable"
                  label="선임일"
                  name="seniorDate"
                  v-model="seniorDismissalStatus.seniorDate"
                />
              </div>
              <div class="col-xs-12 col-sm-12 col-md-3 col-lg-3 col-xl-3">
                <div class="row">
                  <div class="col-xs-12 col-sm-12 col-md-6 col-lg-6 col-xl-6">
                    <c-radio
                      :editable="editable"
                      :comboItems="dismissalFlagItems"
                      label="해임여부"
                      name="dismissalFlag"
                      v-model="seniorDismissalStatus.dismissalFlag"
                      @datachange="datachange">
                    </c-radio>
                  </div>
                  <div class="col-xs-12 col-sm-12 col-md-6 col-lg-6 col-xl-6">
                    <c-datepicker
                      :editable="editable"
                      :disabled="seniorDismissalStatus.dismissalFlag !== 'Y'"
                      label="해임일"
                      :start="seniorDismissalStatus.seniorDate"
                      name="dismissalDate"
                      v-model="seniorDismissalStatus.dismissalDate"
                    />
                  </div>
                </div>
              </div>
            </template>
          </c-card>
          <c-card title="자격증 정보" class="cardClassDetailForm q-mt-sm">
            <template slot="card-detail">
              <div class="col-xs-12 col-sm-12 col-md-3 col-lg-3 col-xl-3">
                <c-text
                  :editable="editable"
                  label="관련자격증"
                  name="relatedCertificate"
                  v-model="seniorDismissalStatus.relatedCertificate">
                </c-text>
              </div>
              <div class="col-xs-12 col-sm-12 col-md-3 col-lg-3 col-xl-3">
                <c-text
                  :editable="editable"
                  label="자격증번호"
                  name="certificateNo"
                  v-model="seniorDismissalStatus.certificateNo">
                </c-text>
              </div>
              <div class="col-xs-12 col-sm-12 col-md-3 col-lg-3 col-xl-3">
                <c-datepicker
                  :editable="editable"
                  label="자격취득일"
                  name="certificateAcquiDate"
                  v-model="seniorDismissalStatus.certificateAcquiDate">
                </c-datepicker>
              </div>
              <div class="col-xs-12 col-sm-12 col-md-12 col-lg-12 col-xl-12">
                <c-upload
                  :attachInfo="attachInfo"
                  :editable="editable"
                  label="관련 선해임자료 및 자격증">
                </c-upload>
              </div>
            </template>
          </c-card>
        </div>
        <div class="col-xs-12 col-sm-12 col-md-12 col-lg-12 col-xl-12">
          <c-table
            ref="table"
            title="관련 교육과정 및 이수일"
            :columns="grid.columns"
            :data="seniorDismissalStatus.eduStatuses"
            :gridHeight="grid.height"
            :filtering="false"
            :isExcelDown="false"
          >
          </c-table>
        </div>
        <!-- <div class="col-xs-12 col-sm-12 col-md-6 col-lg-6 col-xl-6">
          <c-table
            ref="tableHistory"
            title="선임자 이력"
            :columns="gridHistory.columns"
            :data="seniorDismissalStatus.seniorHistory"
            :gridHeight="gridHistory.height"
            :filtering="false"
            :isExcelDown="false"
          >
            <template v-slot:customArea="{ props, col }">
              <template v-if="col.name==='seniorUserName'">
                <template v-if="props.row.seniorStatusId !== popupParam.seniorStatusId">
                  <q-btn
                    class="tableinnerBtn"
                    flat 
                    align="center"
                    color="blue-6"
                    :label="props.row[col.name]"
                    @click.stop="openPopup(props.row)">
                    <q-badge v-if="props.row.retireFlag === 'Y'" color="red" class="scniorBadage">
                      <q-icon name="warning" color="white" class="q-ml-xs" /> 퇴직자
                    </q-badge>
                  </q-btn>
                </template>
                <template v-else>
                  {{props.row[col.name]}}
                  <q-badge class="scniorBadage">
                    <q-icon name="info_outline" color="white" class="q-ml-xs" /> 현재 선임자
                  </q-badge>
                </template>
              </template>
            </template>
          </c-table>
        </div> -->
      </div>
    </q-form>
    <!-- <q-dialog v-model="newSeniorDialog.show" persistent>
      <q-card style="min-width: 350px">
        <q-form ref="editFormDialog">
          <c-card title="선임자 정보" class="cardClassDetailForm">
            <template slot="card-button">
              <q-btn-group outline >
                <c-btn 
                  v-show="editable" 
                  label="저장" 
                  icon="save" 
                  @btnClicked="newSeniorSave"/>
                <c-btn 
                  label='닫기' 
                  icon="cancel" 
                  @btnClicked="closeDialog"
                />
              </q-btn-group>
            </template>
            <template slot="card-detail">
              <div class="col-xs-12 col-sm-12 col-md-12 col-lg-12 col-xl-12">
                <c-field 
                  required
                  :editable="editable"
                  :data="newSeniorDialog"
                  type="dept_user" 
                  deptValue="seniorDeptCd"
                  label="부서 & 선임자" 
                  name="newseniorUserId" 
                  v-model="newSeniorDialog.seniorUserId" />
              </div>
              <div class="col-xs-12 col-sm-12 col-md-12 col-lg-12 col-xl-12">
                <c-datepicker
                  required
                  :editable="editable"
                  default="today"
                  label="선임일"
                  name="newseniorDate"
                  v-model="newSeniorDialog.seniorDate"
                />
              </div>
            </template>
          </c-card>
        </q-form>
      </q-card>
    </q-dialog> -->
    <c-dialog :param="popupOptions"></c-dialog>
  </div>
</template>

<script>
import { uid } from 'quasar'
import selectConfig from '@/js/selectConfig';
import transactionConfig from '@/js/transactionConfig';
export default {
  name: 'senior-dismissal-status-detail',
  props: {
    popupParam: {
      type: Object,
      default: () => ({
        seniorStatusId: '',
      }),
    },
  },
  data() {
    return {
      seniorDismissalStatus: {
        seniorStatusId: '',  // 선해임현황 key
        seniorId: '',  // 선해임명 key
        plantCd: '',  // 사업장
        seniorUserId: '',  // 선임 user
        seniorDate: '',  // 선임일
        relatedCertificate: '',  // 관련자격증
        certificateNo: '',  // 자격증번호
        certificateAcquiDate: '',  // 자격취득일
        relatedReason: '',  // 관련근거
        seniorReason: '',  // 선임사유
        groupId: '',  // 일련번호 키
        sysRevision: '',  // 시스템 개정번호
        revisionNum: '',  // 개정번호
        dismissalFlag: 'N',  // 해임여부
        dismissalDate: '',  // 해임일
        dismissalFlagSave: 'N',  // 해임여부 (저장된 정보)
        dismissalDateSave: '',  // 해임일 (저장된 정보)
        revisionContent: '',  // 개정사유
        regUserId: '',  // 등록자 ID
        chgUserId: '',  // 수정자 ID
        eduStatuses: [],
        // seniorHistory: [],
      },
      grid: {
        columns: [
          {
            name: "educationCourseName",
            field: "educationCourseName",
            label: "교육과정",
            style: 'width:250px',
            align: 'left',
            sortable: false,
          },
          {
            name: "educationCycleName",
            field: "educationCycleName",
            label: "교육주기",
            style: 'width:100px',
            align: 'center',
            sortable: false,
          },
          {
            name: "evaluationDate",
            field: "evaluationDate",
            label: "최근 교육이수일",
            style: 'width:120px',
            align: 'center',
            sortable: false,
          },
          {
            name: "eduNextDate",
            field: "eduNextDate",
            label: "차기 교육이수일",
            style: 'width:120px',
            align: 'center',
            sortable: false,
          },
        ],
        height: '400px'
      },
      // gridHistory: {
      //   columns: [
      //     {
      //       name: "seniorUserName",
      //       field: "seniorUserName",
      //       label: "선임자",
      //       style: 'width:180px',
      //       align: 'center',
      //       sortable: false,
      //       type: 'custom',
      //     },
      //     {
      //       name: "seniorDate",
      //       field: "seniorDate",
      //       label: "선임일",
      //       style: 'width:130px',
      //       align: 'center',
      //       sortable: false,
      //     },
      //     {
      //       name: "dismissalDate",
      //       field: "dismissalDate",
      //       label: "해임일",
      //       style: 'width:130px',
      //       align: 'center',
      //       sortable: false,
      //     },
      //   ],
      //   height: '400px'
      // },
      attachInfo: {
        isSubmit: '',
        taskClassCd: 'DISMISSAL_ATTACH',
        taskKey: '',
      },
      dismissalFlagItems: [
        { code: 'Y', codeName: 'YES' },
        { code: 'N', codeName: 'NO' },
      ],
      // newSeniorDialog: {
      //   show: false,
      //   seniorUserId: '',  // 선임 user
      //   seniorDate: '',  // 선임일
      // },
      editable: true,
      isSave: false,
      mappingType: 'POST',
      detailUrl: '',
      // validUrl: '',
      saveUrl: '',
      deleteUrl: '',
      popupOptions: {
        target: null,
        title: '',
        visible: false,
        width: '70%',
        top: '10px',
        param: {},
        closeCallback: null,
      },
    };
  },
  beforeCreate() {},
  created() {},
  beforeMount() {
    Object.assign(this.$data, this.$options.data());
  },
  mounted() {
    this.init();
  },
  beforeDestroy() {
  },
  computed: {
    isOld() {
      return Boolean(this.popupParam.seniorStatusId)
    }
  },
  methods: {
    init() {
      this.editable = this.$route.meta.editable;
      // url setting
      this.detailUrl = selectConfig.mdm.senior.status.get.url;
      // this.validUrl = selectConfig.mdm.senior.status.valid.url;
      this.saveUrl = transactionConfig.mdm.senior.status.insert.url;
      this.deleteUrl = transactionConfig.mdm.senior.status.delete.url;
      // code setting
      // list setting
      this.getDetail();
    },
    getDetail() {
      if (this.isOld) {
        this.$http.url = this.$format(this.detailUrl, this.popupParam.seniorStatusId);
        this.$http.type = 'GET';
        this.$http.request((_result) => {
          this.$_.extend(this.seniorDismissalStatus, _result.data);
          this.$set(this.seniorDismissalStatus, 'dismissalFlagSave', _result.data.dismissalFlag)
          this.$set(this.seniorDismissalStatus, 'dismissalDateSave', _result.data.dismissalDate)
          this.$set(this.attachInfo, 'taskKey', this.popupParam.seniorStatusId)

          // 상세팝업 최초작성/최근수정 정보 노출
          this.$emit('setRegInfo', _result.data)
        },);
      }
    },
    openSenior() {
      this.popupOptions.title = '선임명 검색';
      this.popupOptions.param = {
        type: 'single',
      };
      this.popupOptions.target = () => import(`${'./seniorDismissalPop.vue'}`);
      this.popupOptions.visible = true;
      this.popupOptions.width = '70%';
      this.popupOptions.closeCallback = this.closeSeniorPopup;
    },
    closeSeniorPopup(data) {
      this.popupOptions.target = null;
      this.popupOptions.visible = false;
      if (data && data.length > 0) {
        this.$set(this.seniorDismissalStatus, 'relatedLaws', data[0].relatedLaws)
        this.$set(this.seniorDismissalStatus, 'seniorName', data[0].seniorName)
        this.$set(this.seniorDismissalStatus, 'qualifiyRequire', data[0].qualifiyRequire)
        this.$set(this.seniorDismissalStatus, 'seniorId', data[0].seniorId)
        /**
         * 선임자 여러명 등록함으로 아래 내용 주석
         */
        // // 이미 해당 선임명으로 등록된 정보가 존재할 시에 해당 선임자 정보를 표시
        // this.$http.url = this.validUrl;
        // this.$http.type = 'GET';
        // this.$http.param = {
        //   plantCd: this.seniorDismissalStatus.plantCd,
        //   seniorId: data[0].seniorId,
        // };
        // this.$http.request((_result) => {
        //   if (_result.data) {
        //     window.getApp.$emit('CONFIRM', {
        //       title: '확인', 
        //       message: `[${data[0].seniorName}] 선임명은 이미 존재합니다.\n해당 선임정보를 보시겠습니까?`,
        //       
        //       type: 'info', // success / info / warning / error
        //       // 확인 callback 함수
        //       confirmCallback: () => {
        //         this.$set(this.popupParam, 'seniorStatusId', _result.data)
        //         this.getDetail();
        //       },
        //       // 취소 callback 함수
        //       cancelCallback: () => {
        //       },
        //     });
        //   } else {
        //     this.$set(this.seniorDismissalStatus, 'relatedLaws', data[0].relatedLaws)
        //     this.$set(this.seniorDismissalStatus, 'seniorName', data[0].seniorName)
        //     this.$set(this.seniorDismissalStatus, 'qualifiyRequire', data[0].qualifiyRequire)
        //     this.$set(this.seniorDismissalStatus, 'seniorId', data[0].seniorId)
        //   }
        // },);
      }
    },
    removeSenior() {
      this.seniorDismissalStatus.relatedLaws = '';
      this.seniorDismissalStatus.seniorName = '';
      this.seniorDismissalStatus.qualifiyRequire = '';
      this.seniorDismissalStatus.seniorId = '';
    },
    datachange() {
      if (this.seniorDismissalStatus.dismissalFlag === 'N') {
        this.seniorDismissalStatus.dismissalDate = ''
      } else {
        this.seniorDismissalStatus.dismissalDate = this.$comm.getToday()
      }
    },
    // addData() {
    //   /**
    //    * 현재 선임자가 해임되었는지 확인
    //    */
    //   if (this.seniorDismissalStatus.dismissalFlagSave !== 'Y' || !this.seniorDismissalStatus.dismissalDateSave) {
    //     window.getApp.$emit('ALERT', {
    //       title: '안내',
    //       message: '현재 선임자를 해임한 후에 진행하시기 바랍니다.',
    //       type: 'warning', // success / info / warning / error
    //     });
    //     return;
    //   }
    //   this.newSeniorDialog.show = true;
    // },
    // newSeniorSave() {
    //   this.$refs['editFormDialog'].validate().then(_result => {
    //     if (_result) {
    //       if (this.newSeniorDialog.seniorUserId === this.seniorDismissalStatus.seniorUserId) {
    //         window.getApp.$emit('ALERT', {
    //           title: '안내',
    //           message: '등록하시려는 선임자가 현재 선임자와 동일합니다.\n다른 사람을 선임하시기 바랍니다.',
    //           type: 'warning', // success / info / warning / error
    //         });
    //         return;
    //       }
    //       window.getApp.$emit('CONFIRM', {
    //         title: '확인', 
    //         message: '새로운 선임자를 선정하시겠습니까?',
    //         
    //         type: 'warning', // success / info / warning / error
    //         // 확인 callback 함수
    //         confirmCallback: () => {
    //           this.$http.url = transactionConfig.mdm.senior.status.insert.url;
    //           this.$http.type = 'POST';
    //           this.$http.param = {
    //             seniorStatusId: '',  // 선해임현황 key
    //             seniorId: this.seniorDismissalStatus.seniorId,  // 선해임명 key
    //             plantCd: this.seniorDismissalStatus.plantCd,  // 사업장
    //             seniorUserId: this.newSeniorDialog.seniorUserId,  // 선임 user
    //             seniorDate: this.newSeniorDialog.seniorDate,  // 선임일
    //             dismissalFlag: 'N',  // 해임여부
    //             relatedReason: this.seniorDismissalStatus.relatedReason,  // 관련근거
    //             seniorReason: this.seniorDismissalStatus.seniorReason,  // 선임사유
    //             regUserId: this.$store.getters.user.userId,  // 등록자 ID
    //           };
    //           this.$http.request((_result) => {
    //             window.getApp.$emit('APP_REQUEST_SUCCESS');
    //             this.closeDialog();
    //             this.popupParam.seniorStatusId = _result.data
    //             this.getDetail();
    //           },);
    //         },
    //         // 취소 callback 함수
    //         cancelCallback: () => {
    //           this.closeDialog();
    //         },
    //       });
    //     } else {
    //       window.getApp.$emit('APP_VALID_ERROR');
    //     }
    //   });
    // },
    // closeDialog() {
    //   this.newSeniorDialog.show = false;
    //   Object.assign(this.$data.newSeniorDialog, this.$options.data().newSeniorDialog);
    // },
    deleteData() {
      window.getApp.$emit('CONFIRM', {
        title: '확인', 
        message: '삭제하시겠습니까?',
        
        type: 'warning', // success / info / warning / error
        // 확인 callback 함수
        confirmCallback: () => {
          this.$http.url = this.$format(this.deleteUrl, this.popupParam.seniorStatusId);
          this.$http.type = 'DELETE';
          this.$http.request(() => {
            window.getApp.$emit('APP_REQUEST_SUCCESS');
            this.$emit('closePopup');
          },);
        },
        // 취소 callback 함수
        cancelCallback: () => {
        },
      });
    },
    // openPopup(row) {
    //   this.popupOptions.title = '선임 현황 상세';
    //   this.popupOptions.param = {
    //     seniorStatusId: row.seniorStatusId
    //   };
    //   this.popupOptions.target = () => import(`${"./seniorDismissalStatusHistoryDetail.vue"}`);
    //   this.popupOptions.width = '80%';
    //   this.popupOptions.visible = true;
    //   this.popupOptions.closeCallback = this.closePopup;
    // },
    // closePopup() {
    //   this.popupOptions.target = null;
    //   this.popupOptions.visible = false;
    // },
    saveData() {
      if (this.isOld) {
        this.saveUrl = transactionConfig.mdm.senior.status.update.url;
        this.mappingType = 'PUT';
      } else {
        this.saveUrl = transactionConfig.mdm.senior.status.insert.url;
        this.mappingType = 'POST';
      }
      this.$refs['editForm'].validate().then(_result => {
        if (_result) {
          window.getApp.$emit('CONFIRM', {
            title: '확인', 
            message: '저장하시겠습니까?',
            
            type: 'info', // success / info / warning / error
            // 확인 callback 함수
            confirmCallback: () => {
              this.seniorDismissalStatus.regUserId = this.$store.getters.user.userId;
              this.seniorDismissalStatus.chgUserId = this.$store.getters.user.userId;

              this.isSave = !this.isSave;
            },
            // 취소 callback 함수
            cancelCallback: () => {
            },
          });
        } else {
          window.getApp.$emit('APP_VALID_ERROR');
        }
      });
    },
    saveCallback(result) {
      window.getApp.$emit('APP_REQUEST_SUCCESS');
      if (!this.isOld) {
        this.popupParam.seniorStatusId = result.data
        this.$set(this.attachInfo, 'taskKey', this.popupParam.seniorStatusId)
        this.$set(this.attachInfo, 'isSubmit', uid())
      }
      this.getDetail();
    },
  }
};
</script>
<style lang="sass">
.scniorBadage 
  font-size: 0.6rem
</style>